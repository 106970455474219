<template>
  <a-modal
    v-model="frequencyVisible"
    dialogClass="frequency_modal"
    title="频次控制"
    @onCancel="handleCancel"
    @ok="handleSubmit"
    :width="700"
  >
    <div class="frequency_wrapper">
      <a-form-model ref="ruleForm" :model="formquery" :rules="rules" v-bind="layout">
        <!-- 广告平台名称 -->
        <a-form-model-item v-if="!isBatch" label="广告平台">
          {{ platName }}
        </a-form-model-item>
        <!-- 广告源ID -->
        <a-form-model-item v-if="!isBatch" label="广告源">
          {{ sourceInfo.dspName }}
        </a-form-model-item>
        <!-- 流量类型 -->
        <a-form-model-item label="流量类型">
          {{ +type === 1 ? '供应商' : '广告位' }}
        </a-form-model-item>
        <FrequencyFormItems v-model="formquery"/>
      </a-form-model>
    </div>
  </a-modal>
</template>

<script>
import {
  frequencyControl
} from '@/apiForManage/flow/config'
import FrequencyFormItems from '../FrequencyFormItems'
export default {
  components: { FrequencyFormItems },
  data () {
    return {
      layout: {
        labelCol: { span: 8 },
        wrapperCol: { span: 14 }
      },
      formquery: {},
      formqueryTemp: {
        requestFreq: undefined,
        requestInterval: undefined,
        impressFreq: undefined,
        clickFreq: undefined,
        impressFreqDay: undefined,
        deviceImpressFreqDay: undefined,
        deviceClickFreqDay: undefined
      },
      rules: {
      }
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    sourceInfo: {
      default: () => ({}),
      type: Object
    },
    dspIdList: {
      default: () => ([]),
      type: Array
    },
    type: {
      default: 1,
      type: [Number, String]
    },
    isBatch: {
      default: false,
      type: Boolean
    }
  },
  watch: {
    visible: {
      handler (val) {
        if (val) {
          if (this.isBatch) {
            this.formquery = { ...this.formqueryTemp }
          } else {
            this.formquery = { ...this.sourceInfo }
          }
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    frequencyVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    },
    platName () {
      return this.sourceInfo?.platName
    }
  },
  methods: {
    handleCancel () {
      this.frequencyVisible = false
    },
    handleSubmit () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          let url = ''
          let query = {}
            url = frequencyControl
            query = {
              ...this.formquery,
              type: this.type,
              ids: this.dspIdList
            }
          const resp = await url(query)
          if (resp.code === 200) {
            this.$message.success('修改成功!')
            this.$emit('changeSource')
            this.frequencyVisible = false
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less">
.frequency_modal .ant-modal-body {
  .frequency_wrapper {
    background-color: #fff;
    border-radius: 5px;
    overflow: hidden;
  }
}
</style>
